import Image from 'next/image';
import styles from '@styles/Navbar.module.css';
import { useEffect, useState } from 'react';
import { NextRouter, useRouter } from 'next/router';
import { Roboto } from 'next/font/google';
import { Tabs, useWindowSize, WindowSize } from '@lib/utils';
import type { Tab } from '@lib/utils';
import Link from 'next/link';

const roboto = Roboto({
  weight: '400',
  subsets: ['latin'],
});

export default function Navbar() {
  const [active, setActive] = useState<boolean>(false);
  const size: WindowSize = useWindowSize();

  const router: NextRouter = useRouter();

  useEffect(() => {
    window.addEventListener('keyup', (e: KeyboardEvent) => {
      switch (e.key) {
        case 'Escape':
          setActive(false);
          break;
      }
    });
  }, []);

  useEffect(() => {
    setActive(false);
  }, [router.asPath]);

  useEffect(() => {
    if (active == true && size.width > 720) {
      setActive(false);
    }
  }, [size.width, active]);

  return (
    <>
      <nav
        className={styles.navbar}
        itemScope
        itemType="https://schema.org/SiteNavigationElement"
      >
        <div className={styles.inner}>
          <Link href={'/'} itemProp="link" title="Startsida">
            {size.width > 350 ? (
              <Image
                className={`${styles.logo} ${
                  router.pathname !== '/' ? ' show' : ''
                }`}
                src="/logos/only_text.webp"
                alt="Zerio Logo"
                width={173}
                height={50}
                priority
                itemProp="logo"
              />
            ) : (
              <></>
            )}
          </Link>

          {size.width > 850 ? (
            <div className={styles.tabList}>
              {Tabs.map((tab: Tab, idx: number) => {
                return (
                  <div key={idx} className={styles.tab}>
                    <Link
                      title={tab.label}
                      href={tab.link}
                      itemProp="link"
                      className={`${styles.link}${
                        router.pathname == tab.link
                          ? ' ' + styles.activeLink
                          : ''
                      } ${roboto.className}`}
                    >
                      {tab.label}
                    </Link>
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              className={`${styles.navToggle}${
                active ? ' ' + styles.active : ''
              }`}
              onClick={() => {
                setActive(!active);
              }}
            >
              <span className={`${styles.line} ${styles.one}`}></span>
              <span className={`${styles.line} ${styles.two}`}></span>
            </div>
          )}
        </div>
      </nav>
      {active ? (
        <div
          className={styles.navmenu}
          itemScope
          itemType="https://schema.org/SiteNavigationElement"
        >
          <div className={styles.inner}>
            {Tabs.map((tab: Tab, idx: number) => {
              return (
                <div key={idx} className={styles.sectionItem}>
                  <Link
                    title={tab.label}
                    href={tab.link}
                    itemProp="link"
                    className={`${styles.link}${
                      router.pathname == tab.link ? ' ' + styles.activeLink : ''
                    } ${roboto.className}`}
                  >
                    {tab.label}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
