import Link from 'next/link';
import Image from 'next/image';
import styles from '@styles/Footer.module.css';
import { NextRouter, useRouter } from 'next/router';
import { Tab } from '@lib/utils';
import { Tabs } from '@lib/utils';

import { Source_Sans_3 } from 'next/font/google';

const description = Source_Sans_3({
  weight: '400',
  subsets: ['latin'],
});

const header = Source_Sans_3({
  weight: '700',
  subsets: ['latin'],
});

export default function Footer() {
  const router: NextRouter = useRouter();

  return (
    <footer className={styles.footer}>
      <div className={styles.infoSection}>
        <div className={styles.section}>
          <h2 className={`${styles.sectionHeader} ${header.className}`}>
            Kontakta oss
          </h2>

          <div className={styles.sectionList}>
            <Link
              className={`${styles.link} ${description.className}`}
              href="mailto:business@zerio.se"
              itemProp="mail"
              title="Zerio AB Mail"
            >
              Mejla oss
            </Link>
            <Link
              className={`${styles.link} ${description.className}`}
              href="http://discord.zerio-scripts.com"
              itemProp="community"
              target="_blank"
              aria-label="Gå med i våran Discord"
              rel="noopener"
              title="Zerio-Scripts Discord"
              aria-describedby="opens in a new window"
            >
              Gå med i våran Discord
            </Link>
          </div>

          <div className={styles.socialList}>
            <Link
              href="https://github.com/Zerio-AB"
              target="_blank"
              aria-label="Github"
              rel="noopener"
              title="Zerio-AB Github"
              aria-describedby="opens in a new window"
            >
              <Image
                className={styles.socialImage}
                src="/social/github.webp"
                alt="Github Logga"
                height={20}
                width={20}
                itemProp="github"
                loading="lazy"
              />
            </Link>
            <Link
              href="https://youtube.com/@Zerio"
              target="_blank"
              aria-label="Youtube"
              rel="noopener"
              title="Zerio's Youtube"
              aria-describedby="opens in a new window"
            >
              <Image
                className={styles.socialImage}
                src="/social/youtube.webp"
                alt="Youtube Logga"
                height={20}
                width={20}
                loading="lazy"
                itemProp="youtube"
              />
            </Link>

            <Link
              href="https://www.instagram.com/zerio.official/"
              target="_blank"
              aria-label="Instagram"
              rel="noopener"
              title="Zerio's Instagram"
              aria-describedby="opens in a new window"
            >
              <Image
                className={styles.socialImage}
                src="/social/instagram.webp"
                alt="Instagram Logga"
                height={20}
                width={20}
                loading="lazy"
                itemProp="instagram"
              />
            </Link>

            <Link
              href="https://twitter.com/zerio_official"
              target="_blank"
              aria-label="Twitter"
              rel="noopener"
              title="Zerio's Twitter"
              aria-describedby="opens in a new window"
            >
              <Image
                className={styles.socialImage}
                src="/social/twitter.webp"
                alt="Twitter Logga"
                height={20}
                width={20}
                loading="lazy"
                itemProp="twitter"
              />
            </Link>
          </div>
        </div>

        <div
          className={styles.section}
          itemScope
          itemType="https://schema.org/Organization"
        >
          <h2 className={`${styles.sectionHeader} ${header.className}`}>
            Vårat bolag
          </h2>

          <div className={styles.sectionList}>
            <p
              className={`${styles.text} ${description.className}`}
              itemProp="legalName"
            >
              Namn: Zerio AB
            </p>
            <p
              className={`${styles.text} ${description.className}`}
              itemProp="leiCode"
            >
              Org. Nummer: 559413-0402
            </p>
            <p
              className={`${styles.text} ${description.className}`}
              itemProp="location"
            >
              Säte: Jönköping, Sweden
            </p>
          </div>
        </div>

        <div className={styles.section}>
          <h2 className={`${styles.sectionHeader} ${header.className}`}>
            Länkar
          </h2>

          <div className={styles.sectionList}>
            {Tabs.map((tab: Tab, idx: number) => {
              return (
                <Link
                  key={idx}
                  href={tab.link}
                  itemProp="link"
                  className={`${styles.navLink}${
                    router.pathname == tab.link ? ' ' + styles.activeLink : ''
                  } ${description.className}`}
                  title={tab.label}
                >
                  {tab.label}
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </footer>
  );
}
