import '@styles/globals.css';
import Navbar from '@components/Navbar';
import Footer from '@components/Footer';
import type { AppProps } from 'next/app';
import { NextSeo } from 'next-seo';
import Script from 'next/script';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-0H2ZPXXKHZ"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(...args){window.dataLayer.push(args);}
          gtag('js', new Date());

          gtag('config', 'G-0H2ZPXXKHZ', {
            cookie_flags: 'max-age=7200;secure;samesite=none'
          });
        `}
      </Script>

      <NextSeo
        title="Webbyrå - Zerio Ⓒ 2023"
        description="Zerio är din hjälpande hand genom den digitala världen. Vi har en lång erfarenhet inom webbutveckling och ett stort utbud av tjänster för hemsidor."
        themeColor="#ee82ee"
        additionalLinkTags={[
          {
            rel: 'apple-touch-icon',
            href: 'https://www.zerio.se/logos/touch-icon-ipad.png',
            sizes: '76x76',
          },
          {
            rel: 'manifest',
            href:
              process.env.NODE_ENV == 'development'
                ? 'http://localhost:3000/manifest.json'
                : 'https://www.zerio.se/manifest.json',
          },
        ]}
        openGraph={{
          type: 'website',
          images: [
            {
              url: 'https://www.zerio.se/logos/only_text.webp',
              alt: 'Zerio Logo',
              type: 'image/webp',
            },
          ],
          title: 'Zerio, din hjälpande hand genom den digitala världen',
          description:
            'Zerio är en webbyrå baserad ut ur Jönköping. Med lång erfarenhet inom vårat fält så kan vi garantera att du får en hemsida som är unik och anpassad efter dina behov.',
          locale: 'sv',
          siteName: 'Zerio',
          url: 'https://www.zerio.se',
        }}
        twitter={{
          handle: '@zerio_official',
          site: 'https://www.zerio.se',
          cardType: 'summary_large_image',
        }}
      />

      <div className="main">
        <Navbar />
        <Component {...pageProps} />
        <Footer />
      </div>
    </>
  );
}
