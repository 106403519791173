import { useState, useEffect } from 'react';

export interface WindowSize {
  width: number;
  height: number;
}

export interface Tab {
  label: string;
  link: string;
}

export interface News {
  id: number;
  attributes: {
    createdAt: string;
    publishedAt: string;
    updatedAt: string;

    header: string;
    description: string;
    backgroundColor: string;
    author: string;
    image: {
      data: {
        id: number;
        attributes: {
          createdAt: string;
          publishedAt: string;
          updatedAt: string;

          formats: Record<
            string,
            {
              url: string;
            }
          >;

          mime: string;
          name: string;
          url: string;

          size: number;
          width: number;
          height: number;
        };
      };
    };
    uid: string;
  };
}

export interface Audit {
  description: string;
  displayValue: string;
  id: string;
  numericUnit: string;
  numericValue: number;
  score: number;
  scoreDisplayMode: string;
  title: string;
}

export interface SiteData {
  totalScore: number;
  scoreColor: string;
  lighthouseResult: {
    audits: Record<string, Audit>;
  };
}

export const URLRegEx =
  /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
export const EmailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export interface EventStruct {
  preventDefault(): void;
}

export interface InfoItem {
  header: string;
  description: string;
}

export interface Customer {
  image: string;
  text: string;
  height: number;
  width?: number;
  link: string;
}

export interface PortfolioCustomer {
  id: number;
  attributes: {
    name: string;
    category: NamedCurve;
    slug: string;
    image: {
      data: {
        id: number;
        attributes: {
          createdAt: string;
          publishedAt: string;
          updatedAt: string;
          caption: string;

          formats: Record<
            string,
            {
              url: string;
            }
          >;

          mime: string;
          name: string;
          url: string;

          size: number;
          width: number;
          height: number;
        };
      };
    };
    logo: {
      data: {
        id: number;
        attributes: {
          createdAt: string;
          publishedAt: string;
          updatedAt: string;
          caption: string;

          formats: Record<
            string,
            {
              url: string;
            }
          >;

          mime: string;
          name: string;
          url: string;

          size: number;
          width: number;
          height: number;
        };
      };
    };
  };
}

export const Tabs: Tab[] = [
  {
    label: 'Startsida',
    link: '/',
  },
  {
    label: 'Om Oss',
    link: '/about',
  },
  {
    label: 'Tjänster',
    link: '/services',
  },
  {
    label: 'Portfolio',
    link: '/portfolio',
  },
  {
    label: 'Kontakta Oss',
    link: '/contact',
  },
];

export const Months: string[] = [
  'Januari',
  'Februari',
  'Mars',
  'April',
  'Maj',
  'Juni',
  'Juli',
  'Augusti',
  'September',
  'Oktober',
  'November',
  'December',
];

export const FaqList: InfoItem[] = [
  {
    header: 'Skräddarsytt',
    description:
      'Alla våra projekt är alltid skräddarsydda för att passa just dina behov. Inom webbutveckling så har alla projekt alltid olika behov och krav, därmed så finns det inte något som passar alla, utan vi väljer att skräddarsy en lösning utifrån dina specifika behov.',
  },
  {
    header: 'Kompetenser',
    description:
      'Vi har en stor kompetens inom mängder olika teknologier & språk inom hemsideutveckling men oftast används Javascript (React / Vue) för självaste hemsidan då det gör för en robust grund som enkelt kan underhållas. Medans den potentiella servern för våra projekt oftast drivs av Rust eller C#<br/>Som kund hos oss har du möjlighet till att föreslå teknologierna eller språk som ska används så det bättre passar era specifika behov.',
  },
  {
    header: 'Hosting',
    description:
      'Du som kund får alltid hjälp att sätta upp eran nya hemsida med valfri hosting, alternativt en host som vi rekommenderar. Du kan även välja att hosta din hemsida direkt genom oss.',
  },
  {
    header: 'Support',
    description:
      'En utav våra största prioriteringar är alltid att du som kund ska få den information, hjälp & liknande som du behöver. Därmed är kundservice en stor del utav våran verksamhet. Vi tror på ett långt samarbete med våra kunder och att vi ska kunna hjälpa er med allt som ni behöver.',
  },
  {
    header: 'Sökmotoroptimering',
    description:
      'Alla våra projekt är alltid optimerade för att fungera så bra som möjligt för sökmotorer. Detta gör så att du som kund kan vara säker på att din hemsida kommer nå ut till alla som söker efter eran tjänst, verksamhet eller liknande.',
  },
  {
    header: 'Mobilanpassning',
    description:
      'Vi ser alltid till att våra projekt är anpassade för att fungera på alla enheter, både mobil, surfplatta och dator. Detta gör att du som kund kan vara säker på att din hemsida kommer fungera för alla som använder den.',
  },
];

export const AboutList: InfoItem[] = [
  {
    header: 'Våran filosofi',
    description:
      'Vi tror på att göra saker rätt från början och undviker därmed alla typer av genvägar. När vi skapar en hemsida åt dig, så gör vi allt från grunden. Detta ger oss en mycket större kontroll och överblick över arbetet som vi utför och vi kan därmed säkerställa att det lever upp till högsta möjliga standard.' +
      '<br/><br/>Genom att arbeta på detta sätt kan vi garantera att din hemsida inte bara ser fantastisk ut, utan också fungerar på bästa möjliga sätt. Vår dedikerade och erfarna utvecklare strävar alltid efter att överträffa dina förväntningar och se till att du får en webbplats som är skräddarsydd för att passa dina specifika behov och önskemål.',
  },
  {
    header: 'Våra framtidsmål',
    description:
      'Vi anser att det är viktigt att ständigt utvecklas och förbättras för att ligga i framkant inom vårt område. Vi ser därför till att våra mål är väldefinierade och mätbara så att vi alltid kan mäta vår framgång och vidta åtgärder vid behov.' +
      '<br/><br/>Ett av våra huvudmål är att leverera högkvalitativa och skräddarsydda webblösningar som uppfyller våra kunders behov och önskemål på bästa sätt. Vi strävar efter att skapa webbplatser som är både visuellt tilltalande och funktionella. Detta gör vi genom att använda den senaste tekniken och de mest effektiva metoderna inom webbutveckling.' +
      '<br/><br/>Ett annat mål vi har är att ha en stark och tillförlitlig kundtjänst. Vi förstår vikten av att ha en bra kommunikation med våra kunder och att vara lyhörda för deras behov och önskemål.' +
      '<br/><br/>Genom att arbeta mot bland annat dessa mål kan vi garantera att vi alltid strävar efter att överträffa våra kunders förväntningar och leverera högkvalitativa webblösningar som hjälper dem att nå sina affärsmål.',
  },
  {
    header: 'Våra teknologier',
    description:
      'Teknologierna som vi använder oss utav beror helt på projektet. Vi har stor kompetens inom mängder av olika teknologier och språk, därmed så kan vi alltid välja den teknologin som passar just ditt projekt bäst.' +
      '<br/><br/>För att skapa en robust och högpresterande hemsida är vår standard att använda NextJS som ramverk för självaste hemsidan och Rust eller C# (ASP.Net) för självaste servern/backenden. Detta är då dessa ramverk kan hantera mycket trafik, presterar bra och kan enkelt underhållas och anpassas. ' +
      '<br/><br/>Vi kan stolt säga att vi har möjligheten till att anpassa teknologierna som vi använder utifrån era behov så ni enklare kan underhålla er hemsida i framtiden, om ni då inte väljer att vi skall underhålla den åt er.',
  },
  {
    header: 'Våra värderingar',
    description:
      'Vi tror på ett långvarigt och hälsosamt samarbete mellan oss och våra kunder. Att ha en hemsida är betydligt mer än skapelsen av första versionen. Det är en oftast en långsiktig investering som man vill kontinuerligt uppdatera och underhålla för att se till att den presterar på bästa sätt.' +
      '<br/><br/>Det kommer ofta upp oväntade förändringar efter hemsidans skapelse, ni kanske ska hålla ett stort evenemang och vill ändra hemsidan för att bättre marknadsföra detta evenemang, eller liknande.',
  },
];

export const Customers: Customer[] = [
  {
    image: 'bolaget.svg',
    text: 'Bolaget Records',
    height: 10 / 6,
    width: 250,
    link: '/customer/bolaget',
  },
  // { image: 'jurediq.png', text: 'Jurediq', height: 323 / 130 },
];

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 1,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}

export function useOutsideAlerter(ref: any, callback: () => void) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
}

export function formatDate(date: string): string {
  const d = new Date(date);

  return `${d.getDay()} ${Months[d.getMonth()]} ${d.getFullYear()}`;
}

export function sortNews(news: News[]): News[] {
  return news.sort((a: News, b: News) => {
    return a.id < b.id ? 1 : -1;
  });
}
